<script>
    import ProfileSidebar from "./ProfileSidebar";
    import moment from "moment"
    /**
     * Profile component
     */
    export default {
        page: {
            title: 'Λογαριασμός'
        },
        components: {ProfileSidebar},
        data() {
            return {
                user: this.$store ? this.$store.state.auth.currentUser : {} || {},
                transactions: [],
                loadedTransactions: false,
                unpaidTransaction: {},
                hasUnpaidHalf: false,
                unpaidHalfId: null
            }
        },
        created: function(){
            this.$store.subscribe((mutation, state) => {
                if(mutation.type === 'auth/SET_CURRENT_USER'){
                    this.user = this.$store.state.auth.currentUser;
                }
            });
            // this.
            this.$store.dispatch('auth/refetchUser');
            this.getTransactions();
        },
        methods:{

            getTransactions(){
                this.$axios.get(`/users/transactions`).then( result => {
                    this.transactions = result.data;
                    this.loadedTransactions = true;
                    this.needsToPayHalf();
                }).catch(e => {
                    this.$notify({group: 'athlisis-notifications', type:'error', duration:5000, title: this.$t('errors.title'), text: 'Η φόρτωση των δεδομένων απέτυχε. Παρακαλώ δοκιμάστε ξανά!'});
                });
            },

            getStatus(status){
                if(status === 'COMPLETED'){
                    return 'Ολοκληρωμένη'
                }

                if(status === 'PENDING'){
                    return 'Αναμένεται πληρωμή'
                }
            },

            needsToPayHalf(){

                for(let transaction of this.transactions){
                    if(transaction.instalments === 2 && transaction.status === 'PENDING'){
                        this.hasUnpaidHalf = true;
                        this.unpaidTransaction = transaction;
                    }
                }
            },

            calculateDaysUntilSubscriptionEnds(){
                let dateFrom = moment();
                let dateTo = moment(this.user.subscriptionDue);

                let difference = dateTo.diff(dateFrom, 'days');
                return 365 - difference;
            },

            calculateDaysUntilSecondDoseEnds(){
                let dateFrom = moment();
                let dateTo = moment(this.unpaidTransaction.dueDate);

                return dateTo.diff(dateFrom, 'days');
            },

            payTransaction(transactionId){
                this.$router.push({name: 'pay-transaction', params: {userTransactionId: transactionId}});
            }


        }
    }
</script>
<template>
    <div id="user-subscription-layout">
        <div class="row">
            <ProfileSidebar></ProfileSidebar>
            <div class="col-lg-9 col-xl-9">
                <b-card>
                    <b-card-title>Πληροφορίες συνδρομής</b-card-title>
                    <b-card-sub-title>Μάθετε πότε λήγει η συνδρομή σας & τις χρεώσεις σας</b-card-sub-title>
                    <b-alert variant="warning" :show="hasUnpaidHalf && calculateDaysUntilSecondDoseEnds() > 0" class="mt-3 w-75">
                        Έχετε {{calculateDaysUntilSecondDoseEnds()}} ημέρες για να εξοφλήσετε τη 2η δόση του athlisis!
                        <b-button variant="link" style="padding:0;margin:0;" @click="payTransaction(unpaidTransaction.id)"><strong>Εξόφληση</strong></b-button>
                    </b-alert>

                    <b-alert variant="danger" :show="hasUnpaidHalf && calculateDaysUntilSecondDoseEnds() <= 0" class="mt-3 w-75">
                        Το περιθώριο για την εξόφληση της 2ης δόσης έχει λήξει.
                        <b-button variant="link" style="padding:0;margin:0;" @click="payTransaction(unpaidTransaction.id)"><strong> Κάντε κλικ εδώ για να ανανεώσετε τη συνδρομή σας.</strong></b-button>
                    </b-alert>

                    <div class="pt-1">

                        <h5>Κόστος ετήσιας συνδρομής: 149,90€ + Φ.Π.Α.</h5>
                        Ημέρες μέχρι την επόμενη ανανέωση:
                        <b-progress v-b-tooltip max="365" animated :title="'Απομένουν ' + (365 - calculateDaysUntilSubscriptionEnds()) + ' μέρες'" class="mt-2 mb-2 w-50">
                            <b-progress-bar :value="calculateDaysUntilSubscriptionEnds()" variant="success"></b-progress-bar>
                        </b-progress>

                        <p>Απομένουν <strong>{{ (365 - calculateDaysUntilSubscriptionEnds()) }} ημέρες</strong> μέχρι την επόμενη ανανέωση</p>

                    </div>


                </b-card>

                <b-card v-if="loadedTransactions" class="transactions">
                    <b-card-title>Ιστορικό συναλλαγών</b-card-title>
                    <b-card-sub-title>Όλες σας οι συναλλαγές</b-card-sub-title>
                    <div class="pt-3">
                        <b-card v-for="transaction of transactions" :key="transaction.id" class="shadow-sm">
                            <b-card-text>
                                <div class="row">
                                    <div class="col-md-2">
                                        <span class="small-size">Ημερομηνία:</span> {{ formatDate(transaction.updatedAt)}}
                                    </div>
                                    <div class="col-md-1">
                                        <span class="small-size">Ποσό:</span> {{transaction.amount.toFixed(2)}} €
                                    </div>
                                    <div class="col-md-1">
                                        <span class="small-size">Δόσεις:</span> {{transaction.instalments}}
                                    </div>
                                    <div class="col-md-2">
                                        <span class="small-size">Είδος συναλλαγής:</span> {{transaction.type === 'PURCHASE' ? 'Αγορά λογισμικού' : 'Ετήσια ανανέωση'}}
                                    </div>
                                    <div class="col-md-2">
                                        <span class="small-size">Κατάσταση:</span> <span :class="transaction.status">{{getStatus(transaction.status)}}</span>
                                    </div>
                                    <div v-if="new Date(transaction.dueDate) > new Date() && transaction.status !== 'COMPLETED' " class="col-md-2">
                                        <span class="small-size">Εξόφληση έως:</span> {{formatDate(transaction.dueDate)}}
                                    </div>
                                    <div v-if="transaction.status !== 'COMPLETED'" class="col-md-2">
                                        <span class="small-size">Πληρωμή</span> <b-button variant="primary" size="xs" @click="payTransaction(transaction.id)" > Πληρωμή τώρα</b-button>
                                    </div>
                                </div>

                            </b-card-text>
                        </b-card>
                    </div>
                </b-card>
            </div>
                <!-- end card-box-->

            <!-- end col -->
        </div>
        <!-- end row-->
    </div>
</template>
<style lang="scss">
    .small-size{
        font-weight:800;
        font-size:0.8rem;
        display: block;
    }
    .PENDING{
        padding:0.1rem;
        background-color: #fff3cd;
        border-color: #ffeeba;
        color: #856404;
    }
    .COMPLETED{
        padding:0.1rem;
        color: #155724;
        background-color: #d4edda;
        border-color: #c3e6cb;
    }
    .FAILED{
        padding:.1rem;
        color: #721c24;
        background-color: #f8d7da;
        border-color: #f5c6cb;
    }
</style>
